<template>
  <div class="article-page">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/admin/index' }">数据概览</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/admin/apps/index' }">应用中心</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/admin/apps/shell/index' }">会议壳</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/admin/apps/shell/meeting-list' }">会议列表</el-breadcrumb-item>
      <el-breadcrumb-item>{{this.$route.query.itemName}}</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/admin/apps/shell/content/index/' + this.$route.params.mId }">会议内容管理</el-breadcrumb-item>
      <el-breadcrumb-item>文章管理</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="page-content">
      <div class="content-header">
        <el-button type="primary" size="mini" @click="addArticle">添加文章</el-button>
      </div>
      <div class="article-list" v-if="dataList.length > 0">
        <div class="article-item" v-for="item in dataList" :key="item.id">
          <div class="left" v-if="item.thumbnail">
            <img :src="item.thumbnail"/>
          </div>
          <div class="right">
            <div class="title">{{item.title}}</div>
            <div class="desc">{{item.content | filtersText}}</div>
            <div class="item-options">
              <div class="edit" @click="editItem(item.id)">编辑</div>
              <div class="del" @click="delItem(item.id)">删除</div>
            </div>
          </div>
        </div>
      </div>
      <div class="no-list" v-else>
        <el-empty :image-size="200"></el-empty>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Index',
  data () {
    return {
      dataList: []
    }
  },
  created () {
    this.getDataList()
  },
  methods: {
    // 获取会议列表
    async getDataList () {
      const { data: res } = await this.$http.get('/shell-article-list', { params: { mId: this.$route.params.mId } })
      if (res.status === 200) {
        this.dataList = res.data
      } else {
        this.$message.error(res.msg)
      }
    },
    // 添加
    addArticle () {
      this.$router.push({ path: '/admin/apps/shell/content/article/' + this.$route.params.mId + '/add', query: { meetingName: this.$route.query.itemName } })
    },
    // 编辑
    editItem (id) {
      this.$router.push({ path: '/admin/apps/shell/content/article/' + this.$route.params.mId + '/edit/' + id, query: { meetingName: this.$route.query.itemName } })
    },
    // 删除
    async delItem (id) {
      const { data: res } = await this.$http.delete('/shell-article/' + id)
      if (res.status === 200) {
        this.$message.success(res.msg)
        await this.getDataList()
      } else {
        this.$message.error(res.msg)
      }
    },
    converCharacter (val) {
      const arrEntities = { lt: '<', gt: '>', nbsp: ' ', amp: '&', quot: '"' }
      return val.replace(/&(lt|gt|nbsp|amp|quot);/ig, function (all, t) { return arrEntities[t] })
    }
  },
  filters: {
    filtersText (value) { // 要判断一下,如果是空就返回空字符串,不然会报错
      if (value != null && value !== '') {
        value = value.replace(/\s*/g, '') // 去掉空格
        value = value.replace(/<[^>]+>/g, '') // 去掉所有的html标记
        value = value.replace(/↵/g, '') // 去掉所有的↵符号
        value = value.replace(/[\r\n]/g, '') // 去掉回车换行
        value = value.replace(/&nbsp;/g, '') // 去掉空格
        const arrEntities = { lt: '<', gt: '>', nbsp: ' ', amp: '&', quot: '"' }
        value.replace(/&(lt|gt|nbsp|amp|quot);/ig, function (all, t) { return arrEntities[t] })
        return value.substr(0, 30)
      } else {
        return ''
      }
    }
  }
}
</script>

<style scoped>
.content-header{
  text-align: right;
}
.article-list{
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  padding: 20px;
}
.article-list .article-item{
  width: 300px;
  height: 140px;
  background-color: rgba(255,255,255,1);
  margin: 0 20px 20px 0;
  position: relative;
  padding-bottom: 24px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.article-list .article-item:hover{
  box-shadow: rgb(0 0 0 / 15%) 0px 15px 25px, rgb(0 0 0 / 5%) 0px 5px 10px;
}
.article-list .article-item .left{
  width: 140px;
  height: 140px;
  background-color: #2d8cf0;
}
.article-list .article-item .left img{
  width: 100%;
  height: 100%;
}
.article-list .article-item .right{
  flex: 1;
  position: relative;
  padding: 4px;
  height: 140px;
}
.article-list .article-item .right .title{
  font-size: 14px;
  font-weight: bold;
  text-align: left;
  margin-bottom: 10px;
}
.article-list .article-item .right .desc{
  font-size: 12px;
  text-align: left;
  color: #909399;
}
.article-list .article-item .item-options{
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 0;
  left: 0;
}
.article-list .article-item .item-options .edit{
  background-color: #409EFF;
  color: #FFFFFF;
  font-size: 13px;
  line-height: 26px;
  cursor: pointer;
  flex: 1;
  text-align: center;
}
.article-list .article-item .item-options .del{
  background-color: #F56C6C;
  color: #FFFFFF;
  font-size: 13px;
  line-height: 26px;
  cursor: pointer;
  flex: 1;
  text-align: center;
}
</style>
